 import React from 'react';
import { graphql } from 'gatsby';
import BasicPage from '../templates/Page';
import { withPreview } from 'gatsby-source-prismic';

const ContactPage = ({ data }) => {
  const title = 'Contact';
  const image = '';
  const description = 'JLP contacts for booking and representation in USA, Canada and Europe.';

  return (
    <BasicPage
      data={data}
      title={title}
      image={image}
      description={description}
    />
  );
};

export default withPreview(ContactPage);

export const query = graphql`
  query {
    prismicBasicPage(
      prismicId: {
        eq: "W28QDygAAODGCVMS"
      }
    ) {
      prismicId
      data {
        page_title {
          html
          text
        }
        body {
          __typename
            ...on PrismicBasicPageBodyText {
              id
              slice_type
              primary {
                text {
                  html
                }
              }
            }
            ...on PrismicBasicPageBodyImageWithCaption {
              id
              slice_type
              items {
                illustration {
                  url
                }
              }
            }
        }
      }
    }
  }
`;
